<template>
  <div>
    <v-navigation-drawer
      :value="isAddEditLensSidebar"
      temporary
      touchless
      floating
      right
      app
      stateless
      :width="$vuetify.breakpoint.xsOnly ? '100%' : 450"
      :class="$vuetify.breakpoint.xsOnly ? 'rounded-0' : 'rounded-l-xl'"
      @input="val => $emit('update:is-add-edit-lens-sidebar', val)"
    >
      <v-card
        flat
        tile
      >
        <v-card-title class="px-4 thead-color">
          <v-avatar
            size="50"
            color="primary"
            class="mr-3"
          >
            <v-icon
              color="white"
              :size="30"
            >
              {{ icons.mdiCircle }}
            </v-icon>
          </v-avatar>
          <span class="font-weight-semibold text-base primary--text">Lens Ekle</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="closeSidebar"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>
        <ValidationObserver
          ref="form"
          tag="div"
        >
          <form @submit.prevent>
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
            >
              <v-autocomplete
                v-model="formField.brand"
                :items="items.brand"
                label="Marka"
                class="rounded-0"
                hide-details
                attach
                eager
                filled
                :error-messages="errors"
                :menu-props="{ bottom: true, offsetY: true }"
                @focus="brandFocus()"
                @change="brandChange()"
              >
                <template #progress>
                  <v-alert
                    :type="loadingSelectStatus"
                    :color="!loadingSelectStatus ? 'primary' : null"
                    text
                    class="ma-0 rounded-0"
                  >
                    <template
                      v-if="loadingSelectStatus === null"
                      #prepend
                    >
                      <v-progress-circular
                        class="mr-3"
                        :width="2"
                        :size="25"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </template>
                    {{ loadingSelectText }}
                  </v-alert>
                </template>
              </v-autocomplete>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
            >
              <v-autocomplete
                v-model="formField.product"
                :items="items.product"
                label="Ürün"
                :disabled="items.brand.length === 0"
                class="rounded-0"
                hide-details
                attach
                eager
                filled
                :error-messages="errors"
                :menu-props="{ bottom: true, offsetY: true }"
                @focus="productFocus()"
                @change="productChange()"
              >
                <template #progress>
                  <v-alert
                    :type="loadingSelectStatus"
                    :color="!loadingSelectStatus ? 'primary' : null"
                    text
                    class="ma-0 rounded-0"
                  >
                    <template
                      v-if="loadingSelectStatus === null"
                      #prepend
                    >
                      <v-progress-circular
                        class="mr-3"
                        :width="2"
                        :size="25"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </template>
                    {{ loadingSelectText }}
                  </v-alert>
                </template>
              </v-autocomplete>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
            >
              <v-autocomplete
                v-model="formField.curve"
                :items="items.curve"
                :disabled="items.product.length === 0"
                label="Temel Eğri"
                class="rounded-0"
                hide-details
                attach
                eager
                filled
                :error-messages="errors"
                :menu-props="{ bottom: true, offsetY: true }"
                @focus="curveFocus()"
                @change="curveChange()"
              >
                <template #progress>
                  <v-alert
                    :type="loadingSelectStatus"
                    :color="!loadingSelectStatus ? 'primary' : null"
                    text
                    class="ma-0 rounded-0"
                  >
                    <template
                      v-if="loadingSelectStatus === null"
                      #prepend
                    >
                      <v-progress-circular
                        class="mr-3"
                        :width="2"
                        :size="25"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </template>
                    {{ loadingSelectText }}
                  </v-alert>
                </template>
              </v-autocomplete>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              rules=""
            >
              <v-autocomplete
                v-model="formField.color"
                :items="items.color"
                :disabled="items.curve.length === 0"
                label="Renk"
                class="rounded-0"
                hide-details
                attach
                eager
                filled
                :error-messages="errors"
                :menu-props="{ bottom: true, offsetY: true }"
                @focus="colorFocus()"
                @change="colorChange()"
              >
                <template #progress>
                  <v-alert
                    :type="loadingSelectStatus"
                    :color="!loadingSelectStatus ? 'primary' : null"
                    text
                    class="ma-0 rounded-0"
                  >
                    <template
                      v-if="loadingSelectStatus === null"
                      #prepend
                    >
                      <v-progress-circular
                        class="mr-3"
                        :width="2"
                        :size="25"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </template>
                    {{ loadingSelectText }}
                  </v-alert>
                </template>
              </v-autocomplete>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
            >
              <v-autocomplete
                v-model="formField.sph"
                :items="items.sph"
                :disabled="items.color.length === 0"
                label="SPH"
                class="rounded-0"
                hide-details
                attach
                eager
                filled
                :error-messages="errors"
                :menu-props="{ bottom: true, offsetY: true }"
                @focus="sphFocus()"
                @change="sphChange()"
              >
                <template #progress>
                  <v-alert
                    :type="loadingSelectStatus"
                    :color="!loadingSelectStatus ? 'primary' : null"
                    text
                    class="ma-0 rounded-0"
                  >
                    <template
                      v-if="loadingSelectStatus === null"
                      #prepend
                    >
                      <v-progress-circular
                        class="mr-3"
                        :width="2"
                        :size="25"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </template>
                    {{ loadingSelectText }}
                  </v-alert>
                </template>
              </v-autocomplete>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              :rules="!disabled.cyl ? 'required' : null"
            >
              <v-autocomplete
                v-model="formField.cyl"
                :items="items.cyl"
                label="CYL"
                class="rounded-0"
                :disabled="disabled.cyl"
                hide-details
                attach
                eager
                filled
                :error-messages="errors"
                :menu-props="{ bottom: true, offsetY: true }"
                @focus="cylFocus()"
                @change="cylChange()"
              >
                <template #progress>
                  <v-alert
                    :type="loadingSelectStatus"
                    :color="!loadingSelectStatus ? 'primary' : null"
                    text
                    class="ma-0 rounded-0"
                  >
                    <template
                      v-if="loadingSelectStatus === null"
                      #prepend
                    >
                      <v-progress-circular
                        class="mr-3"
                        :width="2"
                        :size="25"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </template>
                    {{ loadingSelectText }}
                  </v-alert>
                </template>
              </v-autocomplete>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              :rules="!disabled.axe ? 'required' : null"
            >
              <v-autocomplete
                v-model="formField.axe"
                :items="items.axe"
                :disabled="disabled.axe"
                label="Axe"
                class="rounded-0"
                hide-details
                attach
                eager
                filled
                :error-messages="errors"
                :menu-props="{ bottom: true, offsetY: true }"
                @focus="axeFocus()"
                @change="axeChange()"
              >
                <template #progress>
                  <v-alert
                    :type="loadingSelectStatus"
                    :color="!loadingSelectStatus ? 'primary' : null"
                    text
                    class="ma-0 rounded-0"
                  >
                    <template
                      v-if="loadingSelectStatus === null"
                      #prepend
                    >
                      <v-progress-circular
                        class="mr-3"
                        :width="2"
                        :size="25"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </template>
                    {{ loadingSelectText }}
                  </v-alert>
                </template>
              </v-autocomplete>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              rules="required|min_value:1"
            >
              <v-text-field
                v-model.number="formField.qty"
                min="1"
                type="number"
                label="Miktar"
                class="rounded-0"
                hide-details
                filled
                :error-messages="errors"
              />
            </ValidationProvider>
          </form>
        </ValidationObserver>
        <v-divider />
        <div v-if="stokPrice">
          <v-alert
            v-if="formField.price"
            rounded="0"
            type="info"
            text
            color="primary"
            class="text-caption ma-0"
          >
            Birim Satış Fiyatı : {{ formField.price | currency }}
          </v-alert>
           <v-divider />
          <v-alert v-if=" formField.price_ps" rounded="0" type="info" text color="primary" class="text-caption ma-0">
            Tavsiye Edilen Perakende Satış Fiyatı : {{formField.price_ps | currency}}
          </v-alert>

          <v-alert
            v-if="stockType"
            rounded="0"
            :type="stockType"
            text
            class="text-caption ma-0"
          >
            {{ stockText }}
          </v-alert>
        </div>
      </v-card>
      <v-container
        v-if="$vuetify.breakpoint.xsOnly"
        class="pb-16"
      >
        <v-row
          align="center"
          no-gutters
          class="pb-16"
        >
          <v-col cols="4">
            <v-btn
              plain
              small
              block
              @click="closeSidebar"
            >
              Vazgeç
            </v-btn>
          </v-col>
          <v-col cols="8">
            <v-btn
              color="primary"
              rounded
              x-large
              block
              class="me-3"
              :disabled="stockDisabled"
              @click="submitForm"
            >
              Ekle
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <template
        v-if="!$vuetify.breakpoint.xsOnly"
        v-slot:append
      >
        <v-container>
          <v-row
            align="center"
            no-gutters
          >
            <v-col cols="4">
              <v-btn
                plain
                small
                block
                @click="closeSidebar"
              >
                Vazgeç
              </v-btn>
            </v-col>
            <v-col cols="8">
              <v-btn
                color="primary"
                rounded
                x-large
                block
                class="me-3"
                :disabled="stockDisabled"
                @click="submitForm"
              >
                Ekle
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { useOrderStore } from '@/stores/order'
import axios from '@axios'
import { mdiCircle, mdiClose } from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'

export default {
  model: {
    prop: 'isAddEditLensSidebar',
    event: 'update:is-add-edit-lens-sidebar',
  },
  props: {
    isAddEditLensSidebar: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const userData = JSON.parse(localStorage.getItem('userData'))

    const order = useOrderStore()
    const form = ref(null)
    const stokPrice = ref(false)
    const formField = ref({
      id: null,
      type: 'lens',
      brand: null,
      product: null,
      curve: null,
      color: null,
      sph: null,
      cyl: '',
      axe: '',
      qty: 1,
      title: null,
      price: 0,
      price_ps: 0,
      tax: 0,
      discount: [],
      currency: null,
      tax_rate: null,
      prd_code: null,
    })
    const formFieldCopy = ref({})

    onMounted(() => {
      formFieldCopy.value = { ...formField.value }
    })

    const disabled = ref({
      cyl: true,
      axe: true,
    })
    const disabledCopy = ref({})
    onMounted(() => {
      disabledCopy.value = { ...disabled.value }
    })

    const items = ref({
      brand: [],
      product: [],
      curve: [],
      color: [],
      sph: [],
      cyl: [],
      axe: [],
    })
    const itemsCopy = ref({})
    onMounted(() => {
      itemsCopy.value = { ...items.value }
    })

    const loadingSelectStatus = ref(null)
    const loadingSelectText = ref('Lütfen Bekleyin Yükleniyor')

    const brandFocus = () => {
      const params = new URLSearchParams()
      params.append('method', 'getLensBrands')
      axios
        .post('', params)
        .then(response => {
          if (response.data.error) {
            loadingSelectText.value = response.data.msg
            loadingSelectStatus.value = 'error'

            return
          }

          items.value.brand = response.data.detail.map(({ brandcode, brandname }) => ({
            text: brandname,
            value: brandcode,
          }))
        })
        .catch(e => {
          loadingSelectText.value = 'Bir hata oldu tekrar deneyin'
          loadingSelectStatus.value = 'error'
        })
    }

    const brandChange = () => {
      formField.value.product = null
      formField.value.curve = null
      formField.value.color = null
      formField.value.sph = null
      formField.value.cyl = ''
      formField.value.axe = ''

      items.value.product = []
      items.value.curve = []
      items.value.color = []
      items.value.sph = []
      items.value.cyl = []
      items.value.axe = []

      disabled.value.cyl = true
      disabled.value.axe = true
      stokPrice.value = false
      formField.value.discount = []
      form.value.reset()
    }

    const productFocus = () => {
      const params = new URLSearchParams()
      params.append('method', 'getLensPrdByBrand')
      params.append('brandcode', formField.value.brand)
      axios
        .post('', params)
        .then(response => {
          if (response.data.error) {
            loadingSelectText.value = response.data.msg
            loadingSelectStatus.value = 'error'

            return
          }

          items.value.product = response.data.detail.map(({ prdname }) => ({
            text: prdname,
            value: prdname,
          }))
        })
        .catch(e => {
          loadingSelectText.value = 'Bir hata oldu tekrar deneyin'
          loadingSelectStatus.value = 'error'
        })
    }

    const productChange = () => {
      formField.value.curve = null
      formField.value.color = null
      formField.value.sph = null
      formField.value.cyl = ''
      formField.value.axe = ''

      items.value.curve = []
      items.value.color = []
      items.value.sph = []
      items.value.cyl = []
      items.value.axe = []

      disabled.value.cyl = true
      disabled.value.axe = true
      stokPrice.value = false
      formField.value.discount = []
      form.value.reset()
    }

    const curveFocus = () => {
      const params = new URLSearchParams()
      params.append('method', 'getLensTemelEgri')
      params.append('brandcode', formField.value.brand)
      params.append('prdname', formField.value.product)
      axios
        .post('', params)
        .then(response => {
          if (response.data.error) {
            loadingSelectText.value = response.data.msg
            loadingSelectStatus.value = 'error'

            return
          }

          items.value.curve = response.data.detail.map(({ temelegri }) => ({
            text: temelegri,
            value: temelegri,
          }))
        })
        .catch(e => {
          loadingSelectText.value = 'Bir hata oldu tekrar deneyin'
          loadingSelectStatus.value = 'error'
        })
    }

    const curveChange = () => {
      formField.value.color = null
      formField.value.sph = null
      formField.value.cyl = ''
      formField.value.axe = ''

      items.value.color = []
      items.value.sph = []
      items.value.cyl = []
      items.value.axe = []

      disabled.value.cyl = true
      disabled.value.axe = true
      stokPrice.value = false
      formField.value.discount = []
      form.value.reset()
    }

    const colorFocus = () => {
      const params = new URLSearchParams()
      params.append('method', 'getLensColor')
      params.append('brandcode', formField.value.brand)
      params.append('prdname', formField.value.product)
      params.append('temelegri', formField.value.curve)
      axios
        .post('', params)
        .then(response => {
          if (response.data.error) {
            loadingSelectText.value = response.data.msg
            loadingSelectStatus.value = 'error'

            return
          }

          items.value.color = response.data.detail.map(({ colordesc }) => ({
            text: colordesc || 'Varsayılan Renk',
            value: colordesc,
          }))
        })
        .catch(e => {
          loadingSelectText.value = 'Bir hata oldu tekrar deneyin'
          loadingSelectStatus.value = 'error'
        })
    }

    const colorChange = () => {
      formField.value.sph = null
      formField.value.cyl = ''
      formField.value.axe = ''

      items.value.sph = []
      items.value.cyl = []
      items.value.axe = []

      disabled.value.cyl = true
      disabled.value.axe = true
      stokPrice.value = false
      formField.value.discount = []
      form.value.reset()
    }

    const sphFocus = () => {
      const params = new URLSearchParams()
      params.append('method', 'getLensSPHList')
      params.append('brandcode', formField.value.brand)
      params.append('prdname', formField.value.product)
      params.append('temelegri', formField.value.curve)
      params.append('colordesc', formField.value.color)
      axios
        .post('', params)
        .then(response => {
          if (response.data.error) {
            loadingSelectText.value = response.data.msg
            loadingSelectStatus.value = 'error'

            return
          }

          items.value.sph = response.data.detail.map(({ sph }) => ({
            text: sph,
            value: sph,
          }))
        })
        .catch(e => {
          loadingSelectText.value = 'Bir hata oldu tekrar deneyin'
          loadingSelectStatus.value = 'error'
        })
    }

    const sphChange = () => {
      formField.value.axe = ''

      items.value.axe = []

      disabled.value.axe = true
      stokPrice.value = false
      formField.value.discount = []
      form.value.reset()
      cylFocus()
      axeChange()
    }

    const cylFocus = () => {
      const params = new URLSearchParams()
      params.append('method', 'getLensCYLList')
      params.append('brandcode', formField.value.brand)
      params.append('prdname', formField.value.product)
      params.append('temelegri', formField.value.curve)
      params.append('colordesc', formField.value.color)
      params.append('sph', formField.value.sph)
      axios
        .post('', params)
        .then(response => {
          if (response.data.error) {
            loadingSelectText.value = response.data.msg
            loadingSelectStatus.value = 'error'

            return
          }
          if (response.data.detail.length === 0) {
            disabled.value.cyl = true
          } else {
            disabled.value.cyl = false
            items.value.cyl = response.data.detail.map(({ cyl }) => ({
              text: cyl,
              value: cyl,
            }))
          }
        })
        .catch(e => {
          loadingSelectText.value = 'Bir hata oldu tekrar deneyin'
          loadingSelectStatus.value = 'error'
        })
    }

    const cylChange = () => {
      formField.value.axe = ''

      items.value.axe = []

      disabled.value.axe = true
      stokPrice.value = false

      form.value.reset()
      axeFocus()
    }

    const axeFocus = () => {
      const params = new URLSearchParams()
      params.append('method', 'getLensAXEList')
      params.append('brandcode', formField.value.brand)
      params.append('prdname', formField.value.product)
      params.append('temelegri', formField.value.curve)
      params.append('colordesc', formField.value.color)
      params.append('sph', formField.value.sph)
      params.append('cyl', formField.value.cyl)
      axios
        .post('', params)
        .then(response => {
          if (response.data.error) {
            loadingSelectText.value = response.data.msg
            loadingSelectStatus.value = 'error'

            return
          }
          if (response.data.detail.length === 0) {
            disabled.value.axe = true
          } else {
            disabled.value.axe = false
          }
          items.value.axe = response.data.detail.map(({ axe }) => ({
            text: axe,
            value: axe,
          }))
        })
        .catch(e => {
          loadingSelectText.value = 'Bir hata oldu tekrar deneyin'
          loadingSelectStatus.value = 'error'
        })
    }

    const stockText = ref('')
    const stockType = ref(null)
    const stockStatus = ref(false)
    const stockDisabled = ref(false)
    const stockDetail = ref({ merkez: 0, depo: 0, toplam: 0 })

    const axeChange = () => {
      const params = new URLSearchParams()
      params.append('method', 'getLensItem')
      params.append('brandcode', formField.value.brand)
      params.append('prdname', formField.value.product)
      params.append('temelegri', formField.value.curve)
      params.append('colordesc', formField.value.color)
      params.append('sph', formField.value.sph)
      params.append('cyl', formField.value.cyl)
      params.append('axe', formField.value.axe)
      axios
        .post('', params)
        .then(response => {
          if (response.data.error) {
            loadingSelectText.value = response.data.msg
            loadingSelectStatus.value = 'error'

            return
          }

          formField.value.title = response.data.detail.title
          formField.value.price = response.data.detail.price
          formField.value.price_ps = response.data.detail.price_ps
          formField.value.tax = response.data.detail.tax
          formField.value.currency = response.data.detail.currency
          formField.value.tax_rate = response.data.detail.tax_rate
          formField.value.prd_code = response.data.detail.prd_code
          formField.value.discount = []
          stokPrice.value = true
          stockDetail.value.merkez = 0
          stockDetail.value.depo = 0
          stockDetail.value.toplam = 0
          if (response.data.detail.discRate) {
            formField.value.discount.push(response.data.detail.discRate)

          }
          if (response.data.stock.length > 0) {
            response.data.stock.forEach(item => {
              if (item.WarehouseCode == '3407' || item.WarehouseCode == '3400') {
                stockDetail.value.merkez = item.InventoryQty1 || 0
              } else {
                stockDetail.value.depo = item.InventoryQty1 || 0
              }
            })
            stockDetail.value.toplam = stockDetail.value.depo + stockDetail.value.merkez

            stockFn(stockDetail.value, formField.value.qty)
            stockStatus.value = true
          } else {
            stockDetail.value.merkez = 0
            stockDetail.value.depo = 0
            stockDetail.value.toplam = 0

            stockFn(stockDetail.value, formField.value.qty)
            stockStatus.value = false
          }
        })
        .catch(e => {
          loadingSelectText.value = 'Bir hata oldu tekrar deneyin'
          loadingSelectStatus.value = 'error'
        })
    }
    watch([stockDetail.value, () => formField.value.qty], ([newX, newY]) => {
      stockFn(newX, newY)
    })

    const stockFn = (stock, qty) => {
      stockDisabled.value = false

      if (formField.value.qty > 20) {
        stockText.value = 'Şubede ile temasa geçiniz'
        stockType.value = 'error'
        stockDisabled.value = true

        return
      }

      if (formField.value.qty < 1) {
        stockText.value = 'Değer Geçersiz'
        stockType.value = 'error'
        stockDisabled.value = true

        return
      }
      if (stock.depo >= qty ) {
         //stockText.value = 'Depodaki Ürün Sayısı ' + stock.depo
        stockText.value = 'Şubede stok var ' 
        stockType.value = 'success'
      } else if (stock.depo == 0 && stock.toplam >= qty) {
        // stockText.value = 'Bu ürün merkezden gelecek'
        stockText.value = 'Şubede stok yok , Merkezde Var ' 
        stockType.value = 'warning'
      } else if (stock.depo < qty && stock.toplam >= qty) {
        // stockText.value = `Bu üründen ${stock.depo} adet depodan ${qty - stock.depo} adeti merkezden gelecek`
        stockText.value = 'Şubede stok yok , Merkezde var ' 
        stockType.value = 'warning'
      } else if (stock.toplam < qty) {
        stockDisabled.value = true

        // stockText.value = `Bu kadar ürün yok en fazla ${stock.toplam} adet sipariş edebilirsiniz.`
        stockText.value = 'Stok hiç bir yerde yok.'
        stockType.value = 'error'
      }
    }

    const closeSidebar = () => {
      emit('update:is-add-edit-lens-sidebar', false)

      setTimeout(() => {
        form.value.reset()
        formField.value = { ...formFieldCopy.value }
        formField.value.discount = []
        disabled.value = { ...disabledCopy.value }
        items.value = { ...itemsCopy.value }
      }, 500)
    }

    const submitForm = async () => {
      const isValid = await form.value.validate()

      if (!isValid) {
        return
      }

      const params = new URLSearchParams()
      params.append('method', 'getCampaign')
      params.append('prd_code', formField.value.prd_code)
      params.append('qty', formField.value.qty)

      axios
        .post('', params)
        .then(response => {
          if (response.data.error === false) {
            formField.value.cmpDiscValueType = response.data.cmpDiscValueType
            formField.value.cmpDiscMsg = response.data.msg
            formField.value.discount.push(response.data.cmpDiscValue)
          }
        })
        .finally(() => {
          formField.value.id = Math.floor(Math.random() * (10 - 1 + 1)) + 1
          order.addItem(formField.value)

          if (formField.value.reverse) {
            dialog.value = true
          } else {
            closeSidebar()
          }
        })
    }

    return {
      order,
      form,
      formField,
      items,
      disabled,
      stokPrice,
      loadingSelectText,
      loadingSelectStatus,

      stockStatus,
      stockDetail,
      stockDisabled,
      stockText,
      stockType,

      brandFocus,
      brandChange,
      productFocus,
      productChange,
      curveFocus,
      curveChange,
      colorFocus,
      colorChange,
      sphFocus,
      sphChange,
      cylFocus,
      cylChange,
      axeFocus,
      axeChange,

      closeSidebar,
      submitForm,

      icons: {
        mdiClose,
        mdiCircle,
      },
    }
  },
}
</script>
