// @ts-check
import { useStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
export const useOrderStore = defineStore({
  id: 'order',
  state: () => ({
    rawItems: useStorage('rawItems', []),
    directionGlass: null,
  }),
  getters: {
    items: state =>
      state.rawItems.reduce((items, item) => {
        const userData = JSON.parse(localStorage.getItem('userData'))
        const deepDiscRate = userData.deep_disc_rate

        console.log(deepDiscRate)

        var discountTxt = ''
        var discountPrice = 0
        item.discount.forEach(discount => {
          if (discount === 0 && typeof discount === 'undefined' && typeof discount === null) return
          discountTxt += `%${discount} + `
          discountPrice = (discountPrice || item.price) - ((discountPrice || item.price) * discount) / 100
        })

        //const tax = (dipDiscountPrice * item.tax_rate) / 100
        const tax = ((discountPrice || item.price) * item.tax_rate) / 100
        console.log('item.qty: ' + item.qty)
        console.log('item.tax_rate: ' + item.tax_rate)

        items.push({
          id: item.id,
          prd_code: item.prd_code,
          title: item.title,
          qty: item.qty,
          price: item.price,
          discount: discountTxt.slice(0, -2) || '━',
          discountMsg: item.cmpDiscMsg,
          tax_rate: item.tax_rate,
          currency: item.currency,
          amount: item.price * item.qty,
          //rowAmount: ((discountPrice || item.price) + tax) * item.qty,
          rowAmount: (discountPrice || item.price) * item.qty,
          discountPrice: discountPrice > 0 ? (item.price - discountPrice) * item.qty : 0,
          //dipdiscountPrice: dipDiscountPrice,
          tax: tax * item.qty,
          deep_disc_rate: deepDiscRate,
          customer_id: userData.customer_id,
        })

        console.log('items: ')
        console.log(items)

        return items
      }, []),

    lines: state =>
      state.rawItems.map(({ prd_code, qty, type, brand, direction, fullname, axe }, index) =>
        type === 'stock'
          ? {
              line_num: index + 1,
              prod_type: type,
              prod_code: prd_code,
              qty: qty,
              brand: brand,
              direction: direction.value || 'SOL',
              patient: fullname,
              axe: axe,
            }
          : {
              line_num: index + 1,
              prod_type: type,
              prod_code: prd_code,
              qty: qty,
            },
      ),

    subTotal() {
      return this.items.reduce((sum, a) => sum + a.amount, 0)
    },
    rowTotal() {
      return this.items.reduce((sum, a) => sum + a.rowAmount, 0)
    },

    discountPrice() {
      return this.items.reduce((sum, a) => sum + a.discountPrice, 0)
    },

    dipdiscountPrice() {
      const sumPrice = this.items.reduce((sum, a) => sum + a.amount, 0)
      const sumDiscountPrice = this.items.reduce((sum, a) => sum + a.discountPrice, 0)
      console.log('dip rat 2 : ' + this.items[0].deep_disc_rate)
      const dipPrice = ((sumPrice - sumDiscountPrice) * this.items[0].deep_disc_rate) / 100
      //const dipdiscountPrice = sumPrice - sumDiscountPrice - dipPrice

      console.log('Toplam:' + this.items.reduce((sum, a) => sum + a.amount, 0))
      console.log('İndirim Toplamı:' + this.items.reduce((sum, a) => sum + a.discountPrice, 0))
      console.log('Toplam -İndirim Toplamı:' + (sumPrice - sumDiscountPrice))
      console.log('Dip İndirim Oranı:' + dipPrice)
      //console.log('Dip İndirim Oranı:' + dipdiscountPrice)
      return dipPrice //this.items.reduce((sum, a) => sum + a.dipDiscountPrice, 0)
    },

    taxes() {
      const sumPrice = this.items.reduce((sum, a) => sum + a.amount, 0)
      const sumDiscountPrice = this.items.reduce((sum, a) => sum + a.discountPrice, 0)
      const dip_tutar = this.dipdiscountPrice

      const dip_fark = sumPrice - sumDiscountPrice - dip_tutar
      const tax = (dip_fark * this.items[0].tax_rate) / 100
      console.log(dip_tutar)
      console.log(dip_fark)
      console.log(this.items[0].tax_rate)
      console.log(tax)

      return tax //this.items.reduce((sum, a) => sum + a.tax, 0)
    },
    total() {
      const tax = this.taxes
      const dip_indirim = this.dipdiscountPrice
      const row_total = this.rowTotal
      const sum = row_total - dip_indirim + tax

      return sum
    },

    subTotals() {
      const subTotal = this.subTotal
      const discountPrice = this.discountPrice
      const dipdiscountPrice = this.dipdiscountPrice
      const sum1 = subTotal - discountPrice - dipdiscountPrice

      console.log(subTotal)
      console.log(discountPrice)
      console.log(dipdiscountPrice)
      console.log('Alt Toplam : ' + sum1)

      return sum1
    },
  },
  actions: {
    addItem(name) {
      this.rawItems.push(name)
    },

    removeItem(id) {
      const index = this.rawItems.map(object => object.id).indexOf(id)
      if (index > -1) this.rawItems.splice(index, 1)
    },
  },
})
